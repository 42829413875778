.menu-bar {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    background-image: 
        url('https://www.transparenttextures.com/patterns/broken-noise.png'),
        linear-gradient(0deg, #1b1b1b, #3a3a3a);
    background-blend-mode: overlay, normal;
    border-radius: 10px;
    box-shadow: 2px 2px 10px #0000004d;
    padding: 10px 0;
    width: 60px;
    z-index: 100;
    margin-left: 5px;
    overflow: visible;
    top: 5px; /* Start 5px from the top to ensure padding at the top */
    bottom: 5px; /* 5px from the bottom */
}

.paradigm-logo {
    height: 50px;
    object-fit: contain;
    user-select: none;
}

.logo-item {
    margin-bottom: 20px;
    color: #fff;
    position: relative;
}

.menu-bar-middle {
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 15px;
    justify-content: center;
}

.menu-bar-item {
    border-radius: 8px;
    color: #fff;
    cursor: pointer;
    padding: 13px;
    height: 20px;
    width: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
    transition: all 0.3s;
    position: relative; /* Added for tooltip positioning */
}

.menu-bar-item:hover {
    background-image: 
    url('https://www.transparenttextures.com/patterns/broken-noise.png'),
    linear-gradient(240deg, #343434, #4d4d4d);
}

.menu-bar-top-item {
    color: #fff;
    cursor: default;
    user-select: none;
    transition: color 0.3s ease;
    position: absolute;
}

.profile-icon {
    align-items: center;
    background: #ee6217;
    border-radius: 50%;
    color: #fff;
    display: flex;
    font-size: 18px;
    font-weight: 600;
    height: 44px;
    justify-content: center;
    position: relative;
    text-transform: uppercase;
    width: 44px;
}

/* Tooltip styling */
.menu-bar-tooltip {
    position: absolute;
    top: 50%; 
    left: 60px; 
    transform: translateY(-50%);
    background-color: #333; 
    color: #fff; 
    padding: 8px 12px; 
    border-radius: 8px; 
    white-space: nowrap; 
    font-size: 0.9rem;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.2); 
    z-index: 1000;
    opacity: 0; /* Start as invisible */
    animation: fadeIn 0.3s forwards;
}

.menu-bar-tooltip::before {
    content: "";
    position: absolute;
    top: 50%;
    right: 100%; 
    transform: translateY(-50%);
    border-width: 6px;
    border-style: solid;
    border-color: transparent #333 transparent transparent;
}