.studio-section {
    position: relative;
    margin-top: 390vh;
}

.studio-container {
    gap: 120px;
}

.studio-title-wrapper {
    align-items: flex-start;
    display: flex;
    gap: 10px;
    justify-content: flex-start;
    padding: 0 5.4vw;
    text-align: left;
    width: 100%;
    position: relative;
    z-index: 3;
}

.studio-content-container {
    display: flex;
    color: var(--faded-dark-text);

    justify-content: center;
    align-items: center;
    text-align: left;
    padding: 0 5.4vw;
    position: relative;
    z-index: 3;
    height: 100%;
    width: -webkit-fill-available;
}

.studio-image-container {
    height: 100%;
    width: -webkit-fill-available;
    display: flex;
    justify-content: flex-start;
    align-items: center;
}

.studio-text-element {
    position: relative;
    z-index: 3;
    font-size: 22px;
    color: var(--faded-dark-text);
    padding: 50px 0%;
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    gap: 50px;
    text-align: left;
    font-weight: 700;
    width: -webkit-fill-available;
}

.studio-scroll-text {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}
