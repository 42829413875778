/* Container for the True/False question */
.true-false-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 20px;
  width: 100%;
  text-align: center;
}

/* Question text styling */
.question-text {
  color: #333;
  font-size: 1.7rem;
  font-weight: 700;
  margin-bottom: 1.5rem;
  max-width: 100%;
  text-shadow: 1px 1px 2px #0000001a;
}

/* Container for the two choice boxes */
.tf-choices-container {
  display: flex;
  flex-direction: row;
  gap: 1rem;
  width: 100%;
  max-width: 550px;
  justify-content: center;
}

/* Styling for each True/False option */
.tf-choice-option {
  flex: 1;
  padding: 15px 20px;
  border-radius: 15px;
  font-size: 1.2rem;
  font-weight: 500;
  cursor: pointer;
  transition: all 0.3s ease-in-out;
  background: var(--background-color);
  color: var(--text-color, #333);
  box-shadow: 0px 5px 15px var(--shadow-color);
  backdrop-filter: blur(10px);
}

/* Hover effect */
.tf-choice-option:hover {
  transform: translateY(-2px);
  box-shadow: 0px 10px 20px var(--shadow-color);
}

/* Selected answer effect */
.tf-choice-option.selected {
  transform: scale(1.05);
  background: var(--selected-background);
  color: white;
  box-shadow: 0px 10px 25px var(--selected-shadow);
  font-weight: 700;
}

/* Difficulty-specific custom properties (similar to MultipleChoiceQuestion.css) */
.novice {
  --background-color: linear-gradient(135deg, #d6c6ff, #b19cd9);
  --selected-background: #967bb6;
  --shadow-color: rgba(178, 137, 255, 0.4);
  --selected-shadow: rgba(150, 123, 182, 0.6);
}

.apprentice {
  --background-color: linear-gradient(135deg, #cce5ff, #9cf);
  --selected-background: #70bfff;
  --shadow-color: rgba(112, 191, 255, 0.4);
  --selected-shadow: rgba(96, 170, 235, 0.6);
}

.adept {
  --background-color: linear-gradient(135deg, #b7f0e0, #91d8cc);
  --selected-background: #67b9a8;
  --shadow-color: rgba(103, 185, 168, 0.4);
  --selected-shadow: rgba(75, 153, 140, 0.6);
}

.expert {
  --background-color: linear-gradient(135deg, #ffbdbd, #f99);
  --selected-background: #f46666;
  --shadow-color: rgba(244, 102, 102, 0.4);
  --selected-shadow: rgba(225, 80, 80, 0.6);
}

.master {
  --background-color: linear-gradient(135deg, #fff4cc, #ffe066);
  --selected-background: #ffc107;
  --shadow-color: rgba(255, 193, 7, 0.4);
  --selected-shadow: rgba(230, 170, 0, 0.6);
}
