/* meet copilot */
.LP-meet-copilot-container {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    gap: 50px;
    position: relative;
}

.LP-product-text-wrapper {
    align-items: flex-start;
    display: flex;
    gap: 10px;
    justify-content: flex-start;
    opacity: 0;
    padding: 0 5.4vw;
    text-align: left;
    width: 100%;
}

.LP-meet-copilot-container .LP-product-text-wrapper {
    align-items: center;
    justify-content: center;
    padding: 0;
    width: 80vw;
}

.LP-product-title {
    font-size: 70px;
    color: var(--dark-text);
    font-weight: 700;
    text-wrap: balance;
    margin-bottom: -20px;
}

/* Container for the subheadings */
.LP-pill-subheading-container {
    position: fixed;
    bottom: 100px;
    z-index: 999;
    display: flex;
    align-items: center;

    box-shadow: 0 2px 4px -1px rgba(57,76,96,.15);
    background: rgba(66,66,69,0.7);
    backdrop-filter: saturate(180%) blur(20px);

    border-radius: 50px;
    padding: 10px 7px;
    justify-content: center;
    overflow: hidden;
    width: 0;
    opacity: 0;
}

/* Individual subheading items */
.LP-pill-subheading-item {
    position: relative;
    z-index: 1;
    cursor: pointer;
    -webkit-tap-highlight-color: transparent; /* For Safari (iOS) and Chrome (Android) */
}

.LP-pill-subheading-text {
    color: #f5f5f7;
    font-weight: 600;
    margin: 0;
    padding: 10px 25px;
    white-space: nowrap;
}

/* Active subheading text color */
.LP-pill-subheading-item.active .LP-pill-subheading-text {
    color: #333;
}

/* The sliding pill background */
.LP-sliding-pill {
    position: absolute;
    height: 3em;
    background-color: #fff;
    border-radius: 50px;
    z-index: 0;
    left: 7px;
    width: 128px;
}



/* Carousel */
.LP-meet-copilot-feature-container {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    gap: 20px;
    position: relative;
    overflow-x: scroll;
    overflow-y: hidden;
    scroll-snap-type: x mandatory;
    scrollbar-width: none;
    width: 100%;
}

.LP-meet-AI-carousel {
    display: flex;
    flex-wrap: nowrap;
    gap: 45px;
    width: -moz-fit-content;
    width: fit-content;
    position: relative;
}

.LP-content-section {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 20px;
    border-radius: 25px;
    height: 600px;
    width: 1000px;

    scroll-snap-align: center; /* Snap to the center of the container */
}
  
.content-section.active {
    z-index: 1;
}

.LP-feature-tile {
    color: #0d12169c;
    flex: 1 1;
    font-size: 1.3em;
    font-weight: 600;
    height: -webkit-fill-available;
    position: relative;
    text-align: left;
    width: -webkit-fill-available;
    letter-spacing: 0.231px;

    border-radius: 25px;
    display: flex;
    font-size: 1.5em;
    gap: 10px;
    justify-content: space-between;
    padding: 60px 90px;
    position: relative;
    overflow: hidden;
    color: #f5f5f7;
}

.LP-meet-ai-title {
    color: #ffffff78;
    font-weight: 700;
    font-size: 35px;
    z-index: 99;
}

.LP-meet-ai-title span {
    color: #f5f5f7;
}

.LP-feature-tile .LP-meet-ai-seedling {
    bottom: -10px;
    color: #fff;
    left: 30%;
    position: absolute;
    transform: translateX(-50%);
    z-index: 9;
}


/* What Am I Section */
.LP-what-am-i-tile {
    background-image: 
    url('https://www.transparenttextures.com/patterns/broken-noise.png'),
    linear-gradient(0deg, #1b1b1b, #3a3a3a);
    background-blend-mode: overlay, normal;
}

.LP-what-am-i-tile-title {
    width: 400px;
}

.LP-what-am-i-tile .LP-meet-ai-seedling {
    font-size: 150px;
}

.LP-copilot-mascot-image {
    bottom: -10px;
    height: 620px;
    position: absolute;
    right: 30%;
    transform: translateX(50%);
}







/* Personality Section */
.LP-personality-tile {
/*  background: linear-gradient(199deg, #afaed5, #8f8fb5); */
    background-blend-mode: overlay, normal;
    transition: background 0.3s ease-out;
    position: relative;
    overflow: hidden;
    background: transparent;
}

.LP-gradient-overlay {
    background-blend-mode: overlay, normal;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    pointer-events: none;
    transition: opacity 0.3s ease-out;
    opacity: 0;
    z-index: 1;
}

.LP-personality-title {
    width: 390px;
}

.LP-personality-tile .LP-meet-ai-seedling {
    font-size: 150px;
}

.LP-personality-accordion-container {
    align-items: flex-start;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    padding: 35px 0;
    position: relative;
    width: 310px;
    z-index: 2;
    height: 430px;
    right: 20%;
    transform: translate(50%, -50%);
    top: 50%;
}

.LP-seperator {
    background: #f5f5f791;
    height: 1px;
    width: -webkit-fill-available;
}

.LP-personality-section {
    cursor: pointer;
}

.LP-personality-section.open {
    cursor: default;
}

.LP-personality-1-container,
.LP-personality-2-container,
.LP-personality-3-container,
.LP-personality-4-container {
    width: -webkit-fill-available;
    padding: 24px 0;
}

.LP-personality-1-container {
    padding-top: 0px;
}

.LP-personality-accordion-title-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    color: #f5f5f7;
    font-size: 28px;
}

.LP-personality-accordion-title-container i {
    width: 40px;
    display: inline-block;
}

.LP-personality-accordion-arrow {
    border: 2px solid #f5f5f7;
    border-left: none;
    border-top: none;
    content: "";
    height: 10px;
    transform: rotate(45deg);
    transition: transform .3s ease-out;
    width: 10px;
}

.LP-personality-section.open .LP-personality-accordion-arrow {
    transform: rotate(-135deg);
}

.LP-personality-accordion-text {
    height: 0px;
    overflow: hidden;
    transition: all 0.3s ease-out;
    font-size: 18px;
    font-weight: 400;
    color: #f5f5f7;
}




/* Voice section */
.LP-voice-feature-tile {
    background: linear-gradient(45deg, #3a4278, #7fade8);
    background-blend-mode: overlay, normal;
    background-image: url(https://www.transparenttextures.com/patterns/broken-noise.png), linear-gradient(45deg, #3a4278, #7fade8);
    overflow: hidden;
    position: relative;
}

.LP-voice-title {
    width: 420px;
}

.LP-voice-feature-tile .LP-meet-ai-seedling {
    font-size: 150px;
}

.LP-voice-container {
    position: absolute;
    top: 50%;
    right: 25%;
    transform: translate(50%, -50%);
    display: flex;
    flex-direction: column;
    padding: 35px;
    gap: 14px;
    font-size: 16px;
    font-weight: 700;
    color: #f5f5f7;
    /* background: #44444445; */
}

.LP-voice-select-container {
    display: flex;
    align-items: center;
    gap: 5px;
}

.LP-voice-test-container {
    display: flex;
    align-items: center;
    gap: 10px;
}

.LP-play-button {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 32px;
    height: 30px;
    background-color: #f5f5f7;
    font-size: 17px;
    color: #3b3b3b;
    border-radius: 6px;
    box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.6), 0 2px 3px 0 rgba(0, 0, 0, 0.5);
    cursor: pointer;
    transition: all 0.15s ease-out;
}

.LP-play-button:hover {
    color: #000;
}

.LP-play-button:active {
    color: #000;
}

.LP-sound-wave-container {
    width: 245px;
}



/* About you section */
.LP-about-you-feature-tile {
    background: linear-gradient(179deg, #6386b3, #7fade8, #9abfdf, #5c7da9);
    background-image: url(https://www.transparenttextures.com/patterns/broken-noise.png), linear-gradient(179deg, #86a1c6, #9ebfeb, #b6d1e9, #88a0bf);
    background-blend-mode: overlay, normal;
    overflow: hidden;
}

.LP-about-you-title {
    width: 405px;
    color: #fff;
}

.LP-about-you-feature-tile .LP-meet-ai-seedling {
    font-size: 150px;
}

.about-you-questions-outer-container {
    position: absolute;
    width: 50em;
    height: 100%;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    opacity: 0.6;
}

.about-you-questions-inner-container {
    position: relative;
    width: 100%;
    height: 100%;
}

@keyframes drift {
    from {
        transform: translateX(-45em);
    }
    to {
        transform: translateX(45em);
    }
}

.about-you-questions-inner-container p {
    position: absolute;
    text-wrap: nowrap;
/*    animation: drift 20s linear infinite; */
    color: #f5f5f7;
    right: 0%;
    transform: translateX(100%);
}

#question-1 {
    font-size: 22px;
    opacity: .4;
    top: 1%;
}

#question-2 {
    top: 8%;
    font-size: 20px;
    opacity: 0.4;
}

#question-3 {
    top: 12%;
    font-size: 30px;
    opacity: 0.8;
}

#question-4 {
    top: 18%;
    font-size: 26px;
    opacity: 0.6;
}

#question-5 {
    top: 24%;
    font-size: 20px;
    opacity: 0.4;
}

#question-6 {
    top: 30%;
    font-size: 24px;
    opacity: 0.5;
}

#question-7 {
    top: 36%;
    font-size: 32px;
    opacity: 0.8;
}

#question-8 {
    top: 42%;
    font-size: 24px;
    opacity: 0.5;
}

#question-9 {
    top: 38%;
    font-size: 20px;
    opacity: 0.4;
}

#question-10 {
    top: 59%;
    font-size: 18px;
    opacity: 0.4;
}

#question-11 {
    font-size: 20px;
    opacity: .4;
    top: 56%;
}

#question-12 {
    top: 71%;
    font-size: 26px;
    opacity: 0.6;
}

#question-13 {
    top: 47%;
    font-size: 33px;
    opacity: 1;
}

#question-14 {
    font-size: 22px;
    opacity: .4;
    top: 82%;
}

#question-15 {
    top: 87%;
    font-size: 20px;
    opacity: 0.4;
}

#question-16 {
    top: 85%;
    font-size: 26px;
    opacity: 0.6;
}

#question-17 {
    top: 75%;
    font-size: 30px;
    opacity: 0.8;
}

#question-18 {
    top: 65%;
    font-size: 18px;
    opacity: 0.4;
}

#question-19 {
    top: 4%;
    font-size: 26px;
    opacity: 0.6;
}





/* Privacy section */
.LP-privacy-feature-tile {
    background-image: url(https://www.transparenttextures.com/patterns/broken-noise.png), linear-gradient(0deg,#1b1b1b,#3a3a3a);
    background-blend-mode: overlay, normal;
}

.LP-privacy-title {
    width: 460px;
}

.LP-privacy-feature-tile .LP-meet-ai-seedling {
    font-size: 150px;
}

.LP-privacy-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 25px;
    height: -webkit-fill-available;
    width: 240px;
    position: absolute;
    right: 25%;
    top: 45%;
    transform: translate(50%, -50%);
}

.LP-lock-icon {
    background-clip: text;
    background-image: url(https://www.transparenttextures.com/patterns/broken-noise.png), linear-gradient(146deg, #dcedff, #98c1ff);
    color: #0000;
    font-size: 300px;
}

.privacy-dots-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: -webkit-fill-available;
}

.LP-lock-circle-icon {
    background-clip: text;
    background-image: url(https://www.transparenttextures.com/patterns/broken-noise.png), linear-gradient(146deg, #dcedff, #98c1ff);
    color: #0000;
    font-size: 22px;
}