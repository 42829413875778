/* Chat.css */

.chat-container {
  display: flex;
  height: 100vh;
  transition: all 0.3s ease; /* Smooth transition for layout adjustments */
}

.chat-content {
  align-items: center;
  background: #fff;
  border: 1px solid #e0e1e3;
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  justify-content: flex-end;
  margin: 5px;
  padding: 10px;
  position: relative;
  transition: flex-grow 0.3s ease; /* Smooth transition for flex changes */
}

/* Model Selection Dropdown */
.model-selection {
  border-radius: 8px;
  color: #333;
  cursor: pointer;
  font-size: 1rem;
  padding: 8px 12px;
  z-index: 10;
  position: relative;
}

.model-selection:hover {
  background: #e8e8e8;
}

.model-selected {
  display: flex;
  align-items: center;
  gap: 5px;
  color: #333;
}

.chevron-down {
  font-size: 0.8rem;
  margin-left: 5px;
}

.model-dropdown {
  background: #ededed;
  box-shadow: 0 4px 8px #00000026;
  border: 1px solid #dedede;
  border-radius: 10px;
  flex-direction: column;
  left: 0;
  margin-top: 10px;
  padding: 10px;
  position: absolute;
  top: 100%;
  transition: opacity .3s ease-in-out;
  user-select: none;
  width: 320px;
  z-index: 1000000000;
}

.model-icon {
  border-radius: 50%;
  display: flex;
  height: 18px;
  width: 18px;
  padding: 3px;
  justify-content: center;
  align-items: center;
}

/* Individual model icon styles */
.openai-icon {
  background: #fb6108;
}

.openai-pro-icon {
  background: #874fe7;
}

.claude-haiku-icon {
  background: #303677;
}

.claude-sonnet-icon {
  background: #24544e;
}

.gemini-icon {
  background: #ffffff;
}

.top-chat-options {
  display: flex;
  width: 100%;
  position: absolute;
  top: 10px;
  left: 10px;
  align-items: center;
}

.model-info {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
}

.model-name {
  font-weight: 700;
  margin-bottom: 2px;
  font-size: 0.9rem;
}

.model-description {
  color: #a8a8a8;
  font-size: .7rem;
  width: 220px;
}

.model-selected-check {
  scale: 1.2;
  color: rgb(45, 45, 45);
}

.model-option {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 20px;
  width: 300px;
  height: 50px;
  border-radius: 10px;
  transition: all 0.2s ease-in-out;
  padding: 10px;
}

.model-option:hover {
  background: #e6e6e6;
}

.model-selection.active,
.model-selection:hover {
  background: #e8e8e8; /* Keep the hover background */
}

/* Chat Messages Container */
.chat-messages {
  width: 100%; /* Span the entire width */
  flex-grow: 1;
  margin-top: 50px; /* Adds space for model selection dropdown */
  margin-bottom: 20px;
  padding: 10px;
  overflow-y: auto;
  display: flex;
  justify-content: center; /* Center child elements horizontally */
  scrollbar-width: thin; /* For Firefox */
  scrollbar-color: #cccccc transparent; /* For Firefox */
}

.chat-messages::-webkit-scrollbar {
  width: 8px;
  height: 8px;
}

.chat-messages-inner {
  width: 100%;
  max-width: 40rem; /* Constrain the width of the messages */
  display: flex;
  flex-direction: column;
  gap: 10px; /* Add spacing between messages */
}

.chat-messages::-webkit-scrollbar-thumb {
  background-color: #cccccc; /* The color of the scrollbar thumb */
  border-radius: 4px; /* Rounded edges */
  border: 2px solid transparent; /* Optional space between thumb and track */
}

.chat-messages::-webkit-scrollbar-thumb:hover {
  background-color: #999999; /* Darker shade on hover */
}

.chat-messages::-webkit-scrollbar-track {
  background-color: transparent; /* Optional: track background color */
}

/* Tooltip styling */
.custom-tooltip {
  position: absolute;
  bottom: 145%;
  left: 50%;
  transform: translateX(-50%);
  background-color: #333;
  color: #fff;
  padding: 8px 12px;
  border-radius: 8px;
  white-space: nowrap;
  font-size: 0.9rem;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.2);
  z-index: 10;
  opacity: 0;
  animation: fadeIn 0.3s forwards;
  pointer-events: none;
}

.custom-tooltip::after {
  content: "";
  position: absolute;
  top: 100%;
  left: 50%;
  transform: translateX(-50%);
  border-width: 6px;
  border-style: solid;
  border-color: #333 transparent transparent transparent;
}

@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

.message.user {
  display: flex;
  justify-content: right;
}

.message-content {
  margin-bottom: 10px;
  margin-top: 10px;
  padding: 10px 15px;
  background: linear-gradient(11deg, #171717, #262626);
  border-radius: 20px;
  color: white;
}

.message-content.streaming {
  background: none;
}