body {
    background: #F6F7F9;
    visibility: visible;
}

html {
    overflow-x: hidden;
}

@media (max-width: 1024px) {
    html {
        font-size: 90%; /* Reduces the base font size, affecting all rem units */
    }
}

@media (max-width: 540px) {
    html {
        font-size: 80%; /* Reduces the base font size, affecting all rem units */
    }
}

h1, h2, h3, h4, h5, p, ul {
    padding: 0;
    margin: 0;
}

.main-content {
    width: 100%;
}

.studio-page {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
    overflow: hidden;
}

.centered-footer {
    bottom: 10px;
    color: #666;
    font-size: .9rem;
    left: 50%;
    position: absolute;
    text-align: center;
    transform: translateX(-50%);
}

/* Unique classes for the logo */
.logo-container.studio-logo-container {
    position: absolute;
    top: 20px;
    left: 80px;
    display: flex;
    align-items: center;
    z-index: 1;
}

.studio-logo-text {
    -webkit-background-clip: text;
    background-clip: text;
    background-image: linear-gradient(146deg,#55aee2,#be427b);
    color: transparent;
}

.studio-logo-x {
    color: #f0f0f0;
    display: none;
    font-size: 48px;
    font-style: italic;
    left: 90px;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    z-index: 1;
}

/* Class to hide the logo */
.studio-logo-hidden {
    display: none !important;
}