/* General container styling for the initial page */
.initial-classroom-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-family: 'Inter', sans-serif;
  text-align: center;
  height: 100%;
  width: 100%;
}

/* Heading styling for the initial page */
.initial-classroom-heading {
  font-size: 2.5rem;
  font-weight: bold;
  margin-bottom: 1rem;
  color: #343a40;
}

/* Description styling for the initial page */
.initial-classroom-description {
  font-size: 1.2rem;
  margin-bottom: 2rem;
  color: #6c757d;
}

/* Button styling for the initial page */
.initial-classroom-button {
  padding: 0.8rem 1.5rem;
  font-size: 1rem;
  color: #333; /* Dark grey for text */
  background-color: #f0f0f0; /* Light grey for background */
  border: 1px solid #ccc; /* Light grey border for minimalistic look */
  border-radius: 0.3rem;
  cursor: pointer;
  transition: all 0.3s ease; /* Smooth transition for all properties */
  box-shadow: 0 3px 5px rgba(0, 0, 0, 0.1); /* Subtle shadow for 3D effect */
}

/* Button hover effect for the initial page */
.initial-classroom-button:hover {
  background-color: #e0e0e0; /* Slightly darker grey on hover */
  border-color: #aaa; /* Slightly darker border on hover */
  color: #000; /* Black text on hover */
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2); /* Adjusted shadow on hover */
}

/* Button active effect for the initial page */
.initial-classroom-button:active {
  background-color: #d6d6d6; /* Even darker grey for active state */
  border-color: #999; /* Darker border on active */
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.2); /* Compressed shadow for pressed effect */
  transform: translateY(2px); /* Move the button down slightly for 3D press */
}
