.create-quiz-container {
  display: flex;
  flex-direction: row; /* Horizontal layout for vertical rows */
  gap: 20px;
  justify-content: space-between;
  position: absolute;
}

.vertical-row {
  display: flex;
  flex-direction: column;
  gap: 20px;
  flex: 1; /* Evenly distribute space between the rows */
}

.quiz-topic-tile {
  background: #f2f2f2;
  border: 1px solid #e1e1e1;
  border-radius: 8px;
  box-shadow: 0 4px 6px #0000001a;
  display: flex;
  flex-direction: column;
  height: 150px;
  justify-content: space-between;
  padding: 10px;
}

.quiz-topic-input {
  background-color: initial;
  border: none;
  box-sizing: border-box;
  font-size: 16px;
  height: 100%;
  line-height: 1.5;
  outline: none;
  padding: 0;
  resize: none;
  text-align: left;
  width: 100%;
  font-family: Inter, sans-serif;
  color: #424242; /* Text color */
}

.quiz-topic-input::placeholder {
  color: #424242; /* Placeholder color */
  opacity: 0.7; /* Ensures full visibility */
  user-select: none;
}

.character-counter {
  font-size: 14px;
  color: #424242;
  font-family: Inter, sans-serif;
  text-align: right;
  opacity: 0.8;
  user-select: none;
}

.difficulty-container {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.difficulty-box {
  border-radius: 8px;
  box-shadow: 0 2px 4px #0000001a;
  padding: 10px;
  text-align: center;
  cursor: pointer;
  font-family: Inter, sans-serif;
  font-size: 16px;
  transition: all 0.3s ease;
  color: #424242; /* Default text color */
  user-select: none;
}

/* Novice */
.difficulty-box.novice {
  background: linear-gradient(135deg, #d6c6ff, #b19cd9); /* Pastel Purple Gradient */
  border: 1px solid #b19cd9; /* Border matches main color */
}

/* Apprentice */
.difficulty-box.apprentice {
  background: linear-gradient(135deg, #cce5ff, #99ccff); /* Pastel Blue Gradient */
  border: 1px solid #99ccff; /* Border matches main color */
}

/* Adept */
.difficulty-box.adept {
  background: linear-gradient(135deg, #b7f0e0, #91d8cc); /* Pastel Turquoise Gradient */
  border: 1px solid #91d8cc; /* Border matches main color */
}

/* Expert */
.difficulty-box.expert {
  background: linear-gradient(135deg, #ffbdbd, #ff9999); /* Pastel Red Gradient */
  border: 1px solid #ff9999; /* Border matches main color */
}

/* Master */
.difficulty-box.master {
  background: linear-gradient(135deg, #fff4cc, #ffe066); /* Pastel Gold Gradient */
  border: 1px solid #ffe066; /* Border matches main color */
}

/* Hover Effect */
.difficulty-box:hover {
  filter: brightness(1.1); /* Slightly brighten the gradient and border */
}

/* Selected State */
.difficulty-box.selected-option {
  background: #424242; /* Darker background for selected */
  color: #f2f2f2; /* Light text for contrast */
  border: 1px solid #333333; /* Darker border for selected */
  pointer-events: none;
}

.quiz-types-tile {
  display: flex;
  flex-direction: column;
  gap: 10px; /* Space between rows */
}

.quiz-types-row {
  display: flex;
  gap: 10px; /* Space between boxes */
}

.quiz-type-box {
  align-items: center;
  background: #f2f2f2;
  border: 1px solid #e1e1e1;
  border-radius: 8px;
  box-shadow: 0 2px 4px #0000001a;
  color: #424242;
  cursor: pointer;
  display: flex;
  flex: 1 1;
  font-family: Inter,sans-serif;
  font-size: 14px;
  text-align: center;
  justify-content: center;
  padding: 10px;
  transition: all .3s ease-in-out;
  height: 50px;
  user-select: none;
}

.default-quiz-type-box {
  flex: 2; /* Larger size for the default box */
}

.quiz-type-box:hover {
  background: #e1e1e1;
}

.quiz-type-box.selected-option {
  background: #424242;
  color: #f2f2f2;
  pointer-events: none;
}

.suggested-stars-tile,
.top-stars-tile {
  align-items: center;
  background: #f2f2f2;
  border: 1px solid #e1e1e1;
  border-radius: 8px;
  box-shadow: 0 4px 6px #0000001a;
  color: #424242;
  display: flex;
  font-family: Inter, sans-serif;
  font-size: 16px;
  justify-content: center;
  padding: 10px;
  text-align: center;
}

.suggested-stars-tile {
  height: 300px; /* Height for Suggested Stars */
}

.top-stars-tile {
  flex-grow: 1; /* Fills remaining space */
}

.quote-container {
  align-items: flex-start;
  /* background: #f9f9f9; */
  /* border: 1px solid #e1e1e1; */
  border-radius: 8px;
  /* box-shadow: 0 4px 6px #0000001a; */
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  margin-top: 24px;
}

.quote-text {
  color: #424242;
  font-size: 36px;
  font-weight: 700;
  line-height: 1.4;
  letter-spacing: -0.6px;
  text-align: left;
}

.quote-author {
  color: #555;
  cursor: pointer;
  font-size: 18px;
  font-weight: 400;
  margin-top: 10px;
  overflow: hidden;
  position: relative;
  text-align: left;
  z-index: 1;
  padding: 3px;
  border-radius: 5px;
}

.quote-author::before {
  content: '';
  position: absolute;
  top: 0;
  left: -100%; /* Start completely off the left */
  width: 100%;
  height: 100%;
  background: linear-gradient(90deg, #ff9a9e, #fad0c4); /* Cool, jazzy gradient */
  z-index: -1; /* Ensure it appears behind the text */
  transition: all 0.3s ease-in-out;
}

.quote-author:hover::before {
  animation: slide-in 0.5s forwards; /* Trigger the animation */
}

/* Keyframe animation for left-to-right slide effect */
@keyframes slide-in {
  from {
    left: -100%; /* Start completely off the left */
  }
  to {
    left: 0; /* Fully fill the element */
  }
}

.start-quiz-button {
  background: #f2f2f2;
  border: none;
  border-radius: 8px;
  color: #424242;
  cursor: pointer;
  font-size: 3rem;
  flex: 2;
  padding: 15px 20px;
  text-align: center;
  width: 100%;
  opacity: 0.3;
  pointer-events: none;
  user-select: none;
  transition: background 0.3s ease, color 0.3s ease;
}

.start-quiz-button.enabled {
  background: #424242;
  color: #f2f2f2;
  cursor: pointer;
  opacity: 1;
  pointer-events: all;
}

.start-quiz-button:hover:enabled {
  background: #333333;
}

.section-container {
  display: flex;
  flex-direction: column;
  gap: 10px;
  height: 100%;
  user-select: none;
}

.section-heading {
  font-size: 20px;
  font-weight: 600;
  color: #424242;
  font-family: Inter, sans-serif;
  user-select: none;
}