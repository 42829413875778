/* Sidebar.css */
.sidebar {
    padding: 10px;
    display: flex;
    justify-content: center;
    height: 100%;
}

.sidebar-icon-container {
    display: flex;
    justify-content: space-between;
    width: 100%;
    height: 25px;
    align-items: baseline;
}

.sidebar-close-icon-container,
.sidebar-chat-icon-container,
.sidebar-open-icon-container {
    position: relative; /* Positioning context for tooltips */
}

.sidebar-icon {
    cursor: pointer;
    color: #5d5d5d;
}

.chat-svg-icon,
.settings-svg-icon {
    border-radius: 10px;
    height: 1.6rem;
    padding: 8px;
    width: 1.6rem;
    display: flex;
}

.chat-svg-icon {
    height: 1.73rem;
    width: 1.75rem;
}

.chat-svg-icon:hover,
.settings-svg-icon:hover {
    background: #00000017;
}

/* Tooltip styling */
.sidebar-tooltip, .chat-tooltip {
    position: absolute;
    background-color: #333;
    color: #fff;
    padding: 8px 12px;
    border-radius: 8px;
    white-space: nowrap;
    font-size: 0.9rem;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.2); 
    z-index: 10000; /* Ensure it's above other elements */
    opacity: 1; /* Default to visible for testing */
    pointer-events: auto; /* Allow hover for debugging */
    animation: fadeInSidebar 0.3s forwards;
}

/* Tooltip for Close Sidebar icon */
.sidebar-tooltip {
    top: 50%;
    left: 100%;
    transform: translateY(-50%);
    margin-left: 10px;
}

.sidebar-tooltip::before {
    content: "";
    position: absolute;
    top: 50%;
    right: 100%;
    transform: translateY(-50%);
    border-width: 6px;
    border-style: solid;
    border-color: transparent #333 transparent transparent;
}

/* Tooltip for New Chat icon in top-chat-options */
.chat-tooltip {
    top: 100%; /* Positioned below the icon */
    left: 50%;
    transform: translateX(-50%);
    margin-top: 10px;
}

.chat-tooltip::before {
    content: "";
    position: absolute;
    top: -12px;
    left: 50%;
    transform: translateX(-50%);
    border-width: 6px;
    border-style: solid;
    border-color: transparent transparent #333 transparent;
}

/* Tooltip for Open Sidebar icon in top-chat-options */
.sidebar-open-tooltip {
    top: 50%;
    left: 100%;
    transform: translateY(-50%);
    margin-left: 10px;
}

.sidebar-open-tooltip::before {
    content: "";
    position: absolute;
    top: 50%;
    right: 100%;
    transform: translateY(-50%);
    border-width: 6px;
    border-style: solid;
    border-color: transparent #333 transparent transparent;
}

.fade-in {
    animation: fadeInSidebar 0.3s forwards;
}

/* Keyframes for the fade-in effect */
@keyframes fadeInSidebar {
    0% {
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
}

.classroom-history {
    margin-top: 25px;
    display: flex;
    flex-direction: column;
    gap: 15px;
    overflow-y: auto; /* Enables vertical scrolling */
    max-height: calc(100vh - 70px); /* Adjust height to fit within the sidebar */
    padding-right: 10px; /* Add space for scrollbar */
    scrollbar-width: thin; /* For Firefox: Thin scrollbar */
    scrollbar-color: transparent transparent; /* Default scrollbar hidden */
    width: 100%;
}

.classroom-history:hover {
    scrollbar-color: #d6d6d6 #f9f9f9; /* Lighter scrollbar thumb and track */
}

.classroom-history::-webkit-scrollbar {
    width: 8px; /* Width of the scrollbar */
    background-color: transparent; /* Hide the scrollbar background by default */
}

.classroom-history::-webkit-scrollbar-thumb {
    background-color: transparent; /* Hide the scrollbar thumb by default */
    border-radius: 4px; /* Rounded edges for the thumb */
}

.classroom-history:hover::-webkit-scrollbar-thumb {
    background-color: #d6d6d6; /* Lighter gray scrollbar thumb on hover */
}

.classroom-history:hover::-webkit-scrollbar-track {
    background-color: #f9f9f9; /* Very light gray track on hover */
}

.classroom-history::before {
  content: '';
  display: block;
  height: 2px;
  background: linear-gradient(180deg, #e0e0e0 50%, #ffffff 0);
}

.classroom-history p {
    font-size: 1rem;
    color: #555;
    text-align: center;
    margin: 20px 0;
    line-height: 1.5;
}

.classroom-history ul {
    list-style-type: none;
    margin: 0;
    padding: 0;
    display: flex;
    flex-direction: column;
    gap: 10px;
}

.classroom-history-item {
    align-items: center;
    background: #f2f2f2;
    border-radius: 4px;
    cursor: pointer;
    display: flex;
    height: 20px;
    overflow: hidden;
    padding: 8px;
    transition: all .3s;
    white-space: nowrap;
    width: 200px;
    border: 1px solid #e1e1e1;
    box-shadow: 0 2px 0px #0000001a;
    font-size: 14px;
}

.classroom-history-item:hover {
    background: #e1e1e1;
}

.classroom-history h4 {
    margin-bottom: 10px;
    font-size: 13px;
    user-select: none;
}