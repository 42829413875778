/* ✅ General container styles */
.multiple-choice-container {
  align-items: center;
  display: flex;
  flex-direction: column;
  text-align: left;
  gap: 20px;
  width: 100%;
}

/* ✅ Question text styling */
.question-text {
  color: #333;
  font-size: 1.7rem;
  font-weight: 700;
  margin-bottom: 1.5rem;
  max-width: 100%;
  text-align: center;
  text-shadow: 1px 1px 2px #0000001a;
}

/* ✅ Answer choices container */
.choices-container {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  width: 100%;
  max-width: 550px;
}

/* ✅ Individual answer option - modern Apple-esque button */
.choice-option {
  display: flex;
  align-items: center;
  gap: 0.75rem;
  padding: 15px 20px;
  border-radius: 15px;
  border: none;
  font-size: 1.2rem;
  font-weight: 500;
  cursor: pointer;
  transition: all 0.3s ease-in-out;
  background: var(--background-color);
  color: var(--text-color);
  box-shadow: 0px 5px 15px var(--shadow-color);
  backdrop-filter: blur(10px);
}

/* ✅ Hover effect for a soft highlight */
.choice-option:hover {
  transform: translateY(-2px);
  box-shadow: 0px 10px 20px var(--shadow-color);
}

/* ✅ Selected answer effect */
.choice-option.selected {
  transform: scale(1.05);
  background: var(--selected-background);
  color: white;
  box-shadow: 0px 10px 25px var(--selected-shadow);
  font-weight: 700;
}

/* ✅ Choice key (A, B, C, D) */
.choice-key {
  font-weight: bold;
  color: var(--key-color);
}

/* ✅ Difficulty-specific styling */
.novice {
  --background-color: linear-gradient(135deg, #d6c6ff, #b19cd9);
  --selected-background: #967bb6;
  --shadow-color: rgba(178, 137, 255, 0.4);
  --selected-shadow: rgba(150, 123, 182, 0.6);
  --key-color: #6b518e;
}

.apprentice {
  --background-color: linear-gradient(135deg, #cce5ff, #9cf);
  --selected-background: #70bfff;
  --shadow-color: rgba(112, 191, 255, 0.4);
  --selected-shadow: rgba(96, 170, 235, 0.6);
  --key-color: #4a93cb;
}

.adept {
  --background-color: linear-gradient(135deg, #b7f0e0, #91d8cc);
  --selected-background: #67b9a8;
  --shadow-color: rgba(103, 185, 168, 0.4);
  --selected-shadow: rgba(75, 153, 140, 0.6);
  --key-color: #4a998a;
}

.expert {
  --background-color: linear-gradient(135deg, #ffbdbd, #f99);
  --selected-background: #f46666;
  --shadow-color: rgba(244, 102, 102, 0.4);
  --selected-shadow: rgba(225, 80, 80, 0.6);
  --key-color: #d94343;
}

.master {
  --background-color: linear-gradient(135deg, #fff4cc, #ffe066);
  --selected-background: #ffc107;
  --shadow-color: rgba(255, 193, 7, 0.4);
  --selected-shadow: rgba(230, 170, 0, 0.6);
  --key-color: #c79900;
}