/* Main container with flex display */
.quiz-page {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  color: #000000;
  font-family: Arial, sans-serif;
  position: relative;
  transform: scale(0.5); /* Initial state for GSAP */
  opacity: 0; /* Initial state for GSAP */
  z-index: -1; /* Initial state for GSAP */
}

/* Header for the quiz title */
.quiz-header {
  color: #c7c7c7;
  /* margin-bottom: 20px; */
  text-align: center;
  position: absolute;
  top: 45px;
}

/* Question content container */
.quiz-question {
  align-items: center;
  /* background-color: #f2f2f2; */
  border-radius: 10px;
  display: flex;
  justify-content: center;
  text-align: center;
  width: 100%;
  max-width: 800px;
  height: 500px;
  position: relative; /* Allows positioning for buttons relative to this container */
}

/* Footer container for details and navigation */
.quiz-footer {
  display: flex;
  justify-content: space-between; /* Spread details and navigation across the footer */
  align-items: center;
  width: 100%;
  max-width: 800px; /* Match the width of quiz-question */
  margin-top: 10px;
}

/* Details container */
.quiz-details {
  flex: 1; /* Take up available space to the left */
}

.quiz-difficulty {
    border-radius: 8px;
    color: #424242;
    cursor: default;
    font-size: 16px;
    padding: 5px;
    -webkit-user-select: none;
    user-select: none;
    min-width: 120px;
    display: flex;
    justify-content: center;
}

/* Specific styles for each difficulty */
.quiz-difficulty.novice {
  background: linear-gradient(135deg, #d6c6ff, #b19cd9);
  border: 1px solid #b19cd9;
}

.quiz-difficulty.apprentice {
  background: linear-gradient(135deg, #cce5ff, #9cf);
  border: 1px solid #9cf;
}

.quiz-difficulty.adept {
  background: linear-gradient(135deg, #b7f0e0, #91d8cc);
  border: 1px solid #91d8cc;
}

.quiz-difficulty.expert {
  background: linear-gradient(135deg, #ffbdbd, #f99);
  border: 1px solid #f99;
}

.quiz-difficulty.master {
  background: linear-gradient(135deg, #fff4cc, #ffe066);
  border: 1px solid #ffe066;
}

/* Navigation buttons container */
.quiz-navigation {
  display: flex;
  align-items: center;
  gap: 5px;
}

/* Circular navigation buttons */
.circle-button {
  color: #565656;
  cursor: pointer;
  justify-content: center;
  align-items: center;
  display: flex;
  transition: transform 0.2s ease;
  background: none;
  border: none;
  outline: none;
}

.circle-button:hover {
  transform: scale(1.1); /* Slight zoom effect on hover */
  color: #232323;
}

.circle-button i {
  font-size: 28px; /* Adjust icon size */
}

.question-progress {
  display: flex;
  justify-content: center;
  gap: 10px;
  margin: 20px 0;
}

.progress-circle {
  width: 30px;
  height: 30px;
  border-radius: 50%;
  background-color: #ddd; /* Default color for inactive circles */
  color: #333;
  display: flex;
  justify-content: center;
  align-items: center;
  /* Remove pointer styling since they are no longer clickable */
  cursor: default;
  transition: transform 0.2s ease;
}

/* Optional: If you want inactive circles to have a border */
.progress-circle:not(.active) {
  border: 1px solid #ccc;
}

.progress-circle.active {
  /* The active circle gets its background color inline via style */
  color: #fff;
}

