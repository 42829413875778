/* Testimonials section */
.testimonials-section {
    padding: 100vh 5.4vw 10vh 5.4vh;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 50px;
}

.testimonials-title {
    font-size: 80px;
    color: var(--dark-text);
    font-weight: 700;
    text-wrap: balance;
    text-align: left;
    opacity: 0; 
}

.testimonials-container {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    width: -webkit-fill-available;
    gap: 15px;
}

.testimonials-column {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    gap: 25px;
    flex: 1;
}

.testimonial {
    border-radius: 15px;
    background: var(--light-grey-background);
    height: fit-content;
    padding: 10px 30px 30px 30px;
    box-sizing: border-box;
    text-align: left;
    text-wrap: balance;
    opacity: 0; 
}

.turquoise-gradient {
    background: linear-gradient(12deg, #0C90A0, #00C4CC);
}

.green-gradient {
    background: linear-gradient(12deg, #389355, #6FCB8C);
}

.yellow-gradient {
    background: linear-gradient(12deg, #F9B52D, #FED990);
}

.red-gradient {
    background: linear-gradient(12deg, #E70208, #FD4E53);
}

.pink-gradient {
    background: linear-gradient(12deg, #EC96E5, #F8B7FE);
}

.purple-gradient {
    background: linear-gradient(12deg, #8E4FEE, #D3B5FE);
}

.orange-gradient {
    background: linear-gradient(12deg, #F09486, #F4B5AB);
}

.blue-gradient {
    background: linear-gradient(12deg, #71D8D8, #B8ECEB);
}

.bi-quote {
    color: #fff;
    font-size: 55px;
    text-align: left;
}

.testimonial-text {
    color: #fff;
    font-size: 15px;
    font-weight: 500;
    text-align: left;
}

.community-button-container {
    display: flex;
    justify-content: center;
    width: 100%;
    opacity: 0;
}

.LP-community-button {
    height: fit-content;
    font-size: 15px;
    height: 45px;
    padding: 0 20px;
}

@media (max-width: 1024px) {
    .testimonials-section {
        padding: 5%;
        margin-top: 7vh;
        gap: 3vh;
    }

    .testimonials-title {
        text-align: left;
    }

    .testimonial {
        width: 21vw;
        padding: 5px 15px 20px 15px;
    }
}

@media (max-width: 912px) {
    .testimonials-section {
        padding: 5%;
        margin-top: 7vh;
        gap: 3vh;
    }

    .testimonials-title {
        font-size: 3rem;
        text-align: left;
    }

    .testimonials-column {
        gap: 15px;
    }

    .testimonial {
        width: auto;
        padding: 5px 15px 20px 15px;
    }

    .testimonial-text {
        font-size: 1rem;
    }
}