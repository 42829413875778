.answer-checking-container {
    align-items: center;
    background-color: #fff;
    border-radius: 10px;
    box-shadow: 0 5px 15px #0000005e;
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin: 20px auto;
    padding: 20px;
    text-align: center;
    width: 300px;
    position: absolute;
    user-select: none;
}

.checking-text {
  font-size: 1.5rem;
  color: #333;
}

.dots-container {
  display: flex;
  gap: 5px;
}

.dot {
  font-size: 2rem;
  animation: blink 1.5s infinite;
  opacity: 0.2;
}

.dot:nth-child(1) {
  animation-delay: 0s;
}
.dot:nth-child(2) {
  animation-delay: 0.3s;
}
.dot:nth-child(3) {
  animation-delay: 0.6s;
}
.dot:nth-child(4) {
  animation-delay: 0.9s;
}
.dot:nth-child(5) {
  animation-delay: 1.2s;
}

@keyframes blink {
  0%, 80%, 100% {
    opacity: 0.2;
  }
  40% {
    opacity: 1;
  }
}
