.netflix-background-container {
    overflow: hidden;
    position: absolute;
    top: -10%;
    left: 0;
    width: 100%;
    height: 100vh;
    z-index: 1;
}

.netflix-gradient {
    width: 100%;
    height: 100%;
    position: absolute;
    left: 0;
    top: 0;
    z-index: 2;
    opacity: 0.9;

    /* Dark Theme Gradient */
    /* background: linear-gradient(180deg, #000 15%, #000 50%, #000 70%, #0009 95%, #00000094); */
    
    /* Light Theme Gradient */
    background: linear-gradient(180deg, #ffffffbf 15%, #fff 50%, #fff 70%, #ffffffd1 95%, #ffffff94);
}

.netflix-container-perspective {
    perspective: 500px;
    height: 100%;
    position: relative;
}

@keyframes netflix_move {
    100% {
        transform: rotateX(365deg) rotateY(352deg) rotateZ(10deg) translateX(-190px);
    }
}

.netflix-container-background {
    height: 100%;
    transform: rotateX(375deg) rotateY(352deg) rotateZ(10deg) translateX(1300px);
    transform-style: preserve-3d;
    animation: netflix_move 180s linear infinite alternate;
}

.netflix-row {
    margin: 5px 0;
    display: flex;
    justify-content: flex-end;
    width: 100%;
    flex-wrap: nowrap;
    flex-grow: 1;
    transform: translateX(100px) translateY(-200px);
}

.netflix-thumbnail {
    transform-style: preserve-3d;
    transform: rotateX(20deg) rotateY(0deg);
    min-height: 160px;
    min-width: 116px;
    display: inline-block;
    background-position: center;
    background-size: cover;
    margin: 7px;
    border-radius: 12px;
    transform: skewX(335deg);
    background-color: var(--light-grey-background);
}