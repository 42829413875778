.loading-screen {
  width: 100%;
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 15px;
  z-index: 1000;
  user-select: none;
  font-family: 'Inter', sans-serif;
  letter-spacing: -0.02em;
}

.loading-screen-logo {
  height: 50px;
}

.paradigm-title {
  color: #00000086;
  margin: 0;
  font-size: 20px;
}

.quiz-title {
  color: #383838;
  font-size: 47px;
  margin: 20px;
}

.loading-bar-container {
  width: 20%;
  height: 6px;
  background: #e0e0e0;
  border-radius: 100px;
  box-sizing: border-box;
  overflow: hidden;
  position: relative;
}

.loading-bar {
  width: 100%;
  height: 100%;
  position: relative;
  border-radius: 100px;
  box-sizing: border-box;
  overflow: hidden;
}

.loading-bar::before {
  content: '';
  display: block;
  height: 100%;
  width: var(--progress-width, 0%);
  background: var(--loading-background, linear-gradient(0deg, #4f4e55, #b5b5c6));
  transition: width 0.5s ease-in-out;
  border-radius: 100px;
}

.loading-message {
  color: #333;
  font-size: 16px;
  margin-top: 10px;
}
