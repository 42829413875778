/* Container for open response questions */
.open-response-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 20px;
  width: 100%;
  max-width: 600px;
  margin: 0 auto;
  padding: 0 20px;
}

/* Styling for the question text */
.question-text {
  color: #333;
  font-size: 1.7rem;
  font-weight: 700;
  text-align: center;
  margin-bottom: 1.5rem;
  text-shadow: 1px 1px 2px rgba(0, 0, 0, 0.1);
}

/* Common styling for both textarea and input fields */
.open-response-input {
  width: 100%;
  padding: 15px 20px;
  font-size: 1.2rem;
  border-radius: 10px;
  border: 1px solid #ccc;
  box-shadow: 0px 5px 15px rgba(0, 0, 0, 0.1);
  transition: border-color 0.3s ease;
  background-color: #fff;
  /* Prevent resizing for textarea */
  resize: none;
  font-family: 'Inter', sans-serif;
}

/* Focus effect */
.open-response-input:focus {
  outline: none;
  border-color: #007bff;
}

/* Base styling for the submit answer button */
.submit-answer-button {
  padding: 10px 20px;
  font-size: 1.2rem;
  font-weight: bold;
  color: #fff;
  background-color: #007bff; /* Fallback color */
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s ease;
  margin-top: 10px;
}

/* Disabled state */
.submit-answer-button:disabled {
  background-color: #cccccc;
  cursor: not-allowed;
}

/* Difficulty-specific styling */

/* Novice */
.novice .open-response-input {
  border-color: #b19cd9;
}
.novice .submit-answer-button {
  background-color: #b19cd9;
}
.novice .submit-answer-button:hover:enabled {
  background-color: #967bb6; /* Darker shade for hover */
}

/* Apprentice */
.apprentice .open-response-input {
  border-color: #99ccff;
}
.apprentice .submit-answer-button {
  background-color: #99ccff;
}
.apprentice .submit-answer-button:hover:enabled {
  background-color: #70bfff; /* Darker shade for hover */
}

/* Adept */
.adept .open-response-input {
  border-color: #91d8cc;
}
.adept .submit-answer-button {
  background-color: #91d8cc;
}
.adept .submit-answer-button:hover:enabled {
  background-color: #67b9a8; /* Darker shade for hover */
}

/* Expert */
.expert .open-response-input {
  border-color: #ff9999;
}
.expert .submit-answer-button {
  background-color: #ff9999;
}
.expert .submit-answer-button:hover:enabled {
  background-color: #f46666; /* Darker shade for hover */
}

/* Master */
.master .open-response-input {
  border-color: #ffe066;
}
.master .submit-answer-button {
  background-color: #ffe066;
}
.master .submit-answer-button:hover:enabled {
  background-color: #ffc107; /* Darker shade for hover */
}
