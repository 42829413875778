.classroom-container {
  display: flex;
  flex-direction: row;
  height: 100%;
}

.classroom-content-inner {
  height: 100%;
  overflow-y: auto;
  width: 100%;
  display: flex;
  align-items: flex-end;
  position: relative;
}

.top-classroom-options {
  align-items: center;
  display: flex;
  left: 10px;
  position: absolute;
  top: 10px;
  width: 100%;
  z-index: 999999;
}

.classroom-content {
  align-items: center;
  background: #fff;
  border: 1px solid #e0e1e3;
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  justify-content: flex-end;
  margin: 5px;
  padding: 10px;
  position: relative;
  transition: flex-grow .3s ease;
}

.studio-logo-hidden {
  display: none;
}

.hidden {
  display: none;
}

.chat-icon-container {
  position: relative; /* Ensures tooltip aligns properly */
  display: flex;
  align-items: center;
  justify-content: center;
}