.correct-answer-container {
  background: linear-gradient(135deg, #35bd39, #72d152);
  border-radius: 12px;
  box-shadow: 0 10px 30px #00000052;
  color: #ffffff;
  left: 50%;
  max-width: 480px;
  padding: 15px;
  border-radius: 20px;
  position: absolute;
  text-align: center;
  top: 50%;
  transform: translate(-50%,-50%);
  width: 100%;
  z-index: 1000;
}

.tick-icon {
  font-size: 80px;
  font-weight: bold;
  color: #fff;
}

.explanation-text {
  font-size: 1.2rem;
  margin-top: 12px;
  line-height: 1.5;
  font-weight: 500;
}

.next-button-correct {
  margin-top: 16px;
  background: white;
  color: #28a745;
  border: none;
  padding: 10px 20px;
  font-size: 1rem;
  font-weight: bold;
  border-radius: 8px;
  cursor: pointer;
  transition: all 0.3s ease-in-out;
}

.close-button:hover {
  background: #f0f0f0;
}
