/* Styles for the Welcome Component */

.welcome-section {
    align-items: center;
    display: flex;
    flex-direction: column;
}

.welcome-section h1 {
    color: #5f5f5f;
    font-size: 1.875rem;
    font-weight: 600;
    letter-spacing: -.5px;
    margin-bottom: 25px;
}

.welcome-input {
    align-items: center;
    background: #e0e0e0;
    border: 1px solid #f3f3f3;
    border-radius: 30px;
    display: flex;
    flex-direction: row;
    height: 50px;
    justify-content: space-between;
    margin-bottom: 30px;
    padding: 0 10px;
    width: 50em;
}
  
.welcome-input input[type="text"] {
    background: none;
    border: none;
    color: #151515;
    border-radius: 5px;
    font-size: 1rem;
    outline: none;
    padding: 10px;
    width: 100%;
}

.welcome-input input[type="text"]::placeholder {
    color: #484848;
    opacity: 1;
}

.icon-hovered {
    opacity: 0.7; /* Slightly reduce opacity when hovered */
    transition: opacity 0.3s ease-in-out; /* Ensure the opacity change is smooth */
}
  
.welcome-button-wrapper {
    position: relative;
    display: inline-block;
}

.welcome-button {
    align-items: center;
    background: none;
    border: none;
    border-radius: 5px;
    color: #a0a0a0;
    cursor: pointer;
    display: flex;
    justify-content: center;
    padding: 1px;
}

.welcome-button i {
    font-size: 1.9rem;
}

.icon-active {
    color: #151515;
}

/* Custom Tooltip Styles */
.custom-tooltip {
    position: absolute;
    bottom: 145%; /* Position the tooltip above the button */
    left: 50%;
    transform: translateX(-50%);
    background-color: #333; /* Dark background for the tooltip */
    color: #fff; /* White text for readability */
    padding: 8px 12px; /* Padding to make it visually appealing */
    border-radius: 8px; /* Rounded corners */
    white-space: nowrap; /* Prevent text wrap */
    font-size: 0.9rem; /* Smaller font size */
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.2); /* Add a subtle shadow for depth */
    z-index: 10; /* Ensure the tooltip appears above other elements */
    opacity: 0; /* Start as invisible */
    animation: fadeIn 0.3s forwards; /* Fade-in effect */
}

.custom-tooltip::after {
    content: "";
    position: absolute;
    top: 100%; /* Position the arrow below the tooltip */
    left: 50%;
    transform: translateX(-50%);
    border-width: 6px;
    border-style: solid;
    border-color: #333 transparent transparent transparent; /* Arrow pointing down with matching color */
}

/* Fade-in animation for the tooltip */
@keyframes fadeIn {
    0% {
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
}

/* Existing Styles for Welcome Options */
.welcome-options {
    display: flex;
    flex-wrap: wrap;
    gap: 10px;
    justify-content: center;
}

.option-button {
    align-items: center;
    background-color: #fff;
    border: 1px solid #0000001a;
    border-radius: 30px;
    box-shadow: 0 1px 8px #ffffff1a, 0 2px 0px #00000024;
    color: #000;
    cursor: pointer;
    display: flex;
    gap: 5px;
    padding: 10px 15px;
    transition: box-shadow .3s;
}

.option-button:hover {
    box-shadow: none;
}

.option-button i {
    font-size: 1.2rem;
}

/* Unique colors for option icons */

.file-text-color {
    color: #ff2800;
}

.lightbulb-color {
    color: #fff300;
}

.mortarboard-color {
    color: #07dfb7;
}

.three-dots-color {
    color: black;
}
  