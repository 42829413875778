/* LandingPage.css */
/* Hide body content initially */
body {
    visibility: hidden;
}

/* When fonts are loaded, this class will make the content visible */
body.fonts-loaded {
    visibility: visible;
}

@keyframes fadeInDown20 {
    from {
        opacity: 0;
        transform: translateY(-20px);
    }
    to {
        opacity: 1;
        transform: translateY(0);
    }
}

.delay-0ms {
    animation-delay: 0s; /* Correct delay to 1 second */
}

.delay-200ms {
    animation-delay: 0.2s; /* Set correct delay to 1.5 seconds */
}

.delay-400ms {
    animation-delay: 0.4s; /* Ensure this is correct and intended */
}

.delay-600ms {
    animation-delay: 0.6s; /* Ensure this is correct and intended */
}

.delay-800ms {
    animation-delay: 0.8s; /* Ensure this is correct and intended */
}

@keyframes fadeInUp {
    from {
        opacity: 0;
        transform: translateY(20px);
    }
    to {
        opacity: 1;
        transform: translateY(0);
    }
}

@media (max-width: 1024px) {
    html {
        font-size: 90%; /* Reduces the base font size, affecting all rem units */
    }
}

@media (max-width: 540px) {
    html {
        font-size: 80%; /* Reduces the base font size, affecting all rem units */
    }
}

h1, h2, h3, h4, h5, p, ul {
    padding: 0;
    margin: 0;
}

.landing-page {
    text-align: center;
    background-color: #fff;
    color: var(--dark-text);
    height: 100%;
    position: relative;
}


/* navbar */
.landing-page .navbar {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 12px 25px;
    color: var(--dark-text);
    width: 100vw;
    box-sizing: border-box;
    opacity: 0;
    animation: fadeInDown20 1s ease-in-out forwards;
    position: absolute;
    z-index: 999;
    /* 
    box-shadow: 0 2px 4px -1px rgba(57,76,96,.15);
    background: #2c2c2c14;
    backdrop-filter: saturate(180%) blur(20px);
    */
}

.landing-page .LP-navbar-left {
    display: flex;
    justify-content: start;
    align-items: center;
}

.landing-page .navbar-right {
    display: flex;
    justify-content: end;
    align-items: center;
    gap: 15px;
}

.LP-logo-container {
    position: relative;
    display: flex;
    align-items: center;
    gap: 10px;
}

.LP-paradigm-logo {
    height: 33px;
    -webkit-user-select: none;
    user-select: none;
}

.landing-page .navbar-logo-text {
    cursor: default;
    font-size: 24px;
    font-weight: 300;
    color: #f5f5f7;
}

.landing-page-button-1 {
    height: 45px;
    padding: 0 20px;
}

.landing-page-button-2 {
    height: 45px;
    padding: 0 20px;
    background: #0000;
    color: #fff;
}

/* Tablets in portrait orientation */
@media only screen and (max-width: 820px) {
    .landing-page .nav-links {
        display: none;
    }
}

/* Phones in portrait orientation */
@media only screen and (max-width: 480px) {
    .landing-page .nav-links {
        display: none;
    }
    
    .landing-page .navbar-button-1 {
        display: none;
    }
}





/* Hero section */
.hero-section-2 {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
    overflow: hidden;
    position: relative;
}

.hero-2-image {
    position: absolute;
    top: 0;
    min-height: 100vh;
    min-width: 100vw;
}

.hero-2-text-container {
    top: 50%;
    left: 8%;
    position: absolute;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    transform: translateY(-50%);
}

.hero-2-title {
    text-align: left;
    color: #F5F5F7;
    font-size: 70px;
    font-weight: 300;
    letter-spacing: -1px;
    user-select: none;
}

.hero-2-title-gradient {
    background-image: linear-gradient(146deg, #55aee2, hsl(332.76deg 48.88% 50.28%));
    -webkit-background-clip: text;
    background-clip: text;
    color: transparent;
}

.hero-button {
    margin-top: 2em;
}



/* Features section */
.features-section {
    padding: 10vh 0;
    margin-top: 8vh;
    display: flex;
    flex-direction: column;
    gap: 140px;
}

.copilot-container,
.studio-container,
.mind-map-container {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: flex-start;
    gap: 50px;
    transition: margin-top 0.3s ease; /* Optional for smooth margin change on resize */
    position: relative;
}

.feature-title {
    text-wrap: balance;
    color: var(--dark-text);
    font-size: 40px;
    font-weight: 700;
}

.feature-title-2 {
    text-wrap: balance;
    color: var(--faded-dark-text);
    font-size: 40px;
    font-weight: 700;
}

/* Introducing Mind Map Screen */
.mind-map-title-container {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    opacity: 0;
    z-index: -1;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
    color: #f5f5f7;
    font-weight: 700;
}

.mind-map-title-1 {
    font-size: 100px;
    color: #ffffff86;
}

.mind-map-title-2 {
    font-size: 100px;
}





/* Footer sectoin */
.footer-section {
    padding: 50px;
    box-sizing: border-box;
    margin-bottom: 70px;
}

.footer-seperator {
    width: -webkit-fill-available;
    height: 1px;
    background: rgb(210,210,215);
    margin-bottom: 50px;
}

.footer-content-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    gap: 20px;
    color: var(--faded-dark-text);
}

.terms-container {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    gap: 10px;
}




/* Scroll banner */
.bottom-banner {
    box-sizing: border-box;
    position: fixed;
    bottom: 0px;
    width: 100vw;
    display: flex;
    justify-content: space-around;
    align-items: center;
    padding: 12px;
    background-color: #fff;
    color: var(--dark-text);
    box-shadow: 0 -16px 20px rgba(14,19,24,.04);
    z-index: 1000;
    transition: transform 0.2s ease-out;
    transform: translateY(100%);

    box-shadow: 0 2px 4px -1px rgba(57,76,96,.15);
    background: #b6b6b652;
    backdrop-filter: saturate(180%) blur(20px);
}

.bottom-banner.show {
    transform: translateY(0);
}

.bottom-banner.hide {
    transform: translateY(100%);
}

.LP-bottom-banner-button {
    height: 40px;
    padding: 0 17px;
}

@media (max-width: 768px) {
    .bottom-banner-text {
        display: none;
    }
}