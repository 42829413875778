.mind-map-canvas {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: #000;
    opacity: 0;
    z-index: -1;
    padding-top: 5%;
    background-position: center;
    background-size: cover;
}

.mind-map-video {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover; /* Makes sure the video covers the area without distortion */
    z-index: -1; /* Keeps it behind other elements */
}

.mind-map-gradient-overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: linear-gradient(to bottom,rgba(0,0,0,0.8) 15%,rgba(0,0,0,0.80) 50%,rgba(0,0,0,0.95) 70%,rgba(0,0,0,0.6) 95%, rgba(0,0,0,1) 100%);
    opacity: 0.7;
    z-index: 0; /* Keeps it behind other elements */
}

.mind-map-container {
    margin-top: 115vh;
    align-items: center;
    text-align: left;
    position: relative;
    z-index: 3;
}

.landing-page-mind-map-text {
    color: #fff;
    font-size: 56px;
    font-weight: 700;
    width: 65%;
}
