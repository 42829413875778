/* Copilot Section #2 (airpod pro 2 & 4) */
.copilot-section {
    position: relative;
}

.copilot-container {
    gap: 20px;
}

.copilot-title-wrapper {
    align-items: flex-start;
    display: flex;
    gap: 10px;
    justify-content: flex-start;
    padding: 0 5.4vw;
    text-align: left;
    width: 100%;
    position: relative;
    z-index: 3;
}

.copilot-content-container {
    display: flex;
    color: var(--faded-dark-text);

    justify-content: center;
    align-items: center;
    text-align: left;
    padding: 0 5.4vw;
    position: relative;
    z-index: 3;
    height: 580px;
    width: -webkit-fill-available;
}

.copilot-image-container {
    height: 580px;
    width: -webkit-fill-available;
    display: flex;
    justify-content: center;
    align-items: center;
}

.copilot-image {
    height: 580px;
    width: auto;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}

.copilot-voice-image {
    height: 520px;
}

.copilot-text-element {
    position: relative;
    z-index: 3;
    font-size: 22px;
    color: var(--faded-dark-text);
    padding: 50px 0%;
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    gap: 50px;
    text-align: left;
    font-weight: 700;
    width: -webkit-fill-available;
}

.copilot-scroll-text-left,
.copilot-scroll-text-right {
    color: var(--faded-dark-text);
    width: 21%;
    text-align: left;
    font-size: 22px;
    font-weight: 400;
}

.copilot-scroll-text-left {
    position: absolute;
    top: 50%;
    left: 7%;
    transform: translateY(-50%);
}

.copilot-scroll-text-right {
    position: absolute;
    top: 50%;
    right: 7%;
    transform: translateY(-50%);
}
