/* Outer container: 93% of viewport height */
.quiz-results {
  height: 93vh;
  display: flex;
  flex-direction: row;
  gap: 20px;
  box-sizing: border-box;
  font-family: Inter, sans-serif;
}

/* Extra info column on the left */
.quiz-results__extra-info {
  display: flex;
  flex-direction: column;
  gap: 10px;
  width: 200px;
  border-radius: 10px;
  padding: 10px;
  box-sizing: border-box;
}

/* Extra info items */
.quiz-results__time,
.quiz-results__score,
.quiz-results__has-passed {
  align-items: center;
  background: #f2f2f2;
  border: 1px solid #dddee0;
  border-radius: 10px;
  display: flex;
  font-size: 60px;
  font-weight: 600;
  height: 90px;
  justify-content: center;
}

.quiz-results__score {
  height: 200px;
}

.quiz-results__has-passed {
  height: 200px;
}

/* Tiles grid on the right */
.quiz-results__tiles {
  flex: 1;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-template-rows: repeat(5, 1fr);
  gap: 10px;
  /* Ensure the grid fits within the available space */
  overflow: hidden;
}

/* Base tile styling */
.results-tile {
  background: #f2f2f2;
  border: 1px solid #e1e1e1;
  border-radius: 10px;
  padding: 10px;
  text-align: center;
  cursor: pointer;
  transition: transform 0.2s ease;
  aspect-ratio: 1; /* Ensures square shape */
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: relative;
  overflow: hidden;
  font-size: xxx-large;
}

/* Hover effect */
.results-tile:hover {
  transform: translateY(-3px);
}

/* Expanded tile: span both columns and show details */
.results-tile--expanded {
  grid-column: span 2;
  text-align: left;
}

/* Tile number */
.results-tile__number {
  font-size: 1.2em;
  font-weight: bold;
}

/* Details inside an expanded tile */
.results-tile__details {
  margin-top: 5px;
  font-size: 0.9em;
  text-align: left;
}

/* Correct tile: green gradient */
.results-tile--correct {
  background: linear-gradient(135deg, #d1f7c4, #a8e6a1);
  border: 1px solid #28a745;
}

/* Incorrect tile: red gradient */
.results-tile--incorrect {
  background: linear-gradient(135deg, #f8d7da, #f5c6cb);
  border: 1px solid #dc3545;
}
