.profile-container {
  position: absolute;
  top: 10px;
  right: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1000;
}

.profile-icon {
  align-items: center;
  background: #ee6217;
  border-radius: 50%;
  color: #fff;
  display: flex;
  font-size: 18px;
  font-weight: 600;
  height: 44px;
  justify-content: center;
  text-transform: uppercase;
  width: 44px;
  cursor: pointer;
  transition: background 0.3s ease;
}

.profile-icon:hover {
  background: #d55814; /* Slightly darker hover effect */
}

.profile-icon-tooltip {
  position: absolute;
  top: 50%;
  right: 60px;
  transform: translateY(-50%);
  background-color: #333;
  color: #fff;
  padding: 8px 12px;
  border-radius: 8px;
  white-space: nowrap;
  font-size: 0.9rem;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.2);
  z-index: 1000;
  opacity: 1;
  animation: fadeIn 0.3s forwards;
}

.profile-icon-tooltip::before {
  content: "";
  position: absolute;
  top: 50%;
  left: 100%;
  transform: translateY(-50%);
  border-width: 6px;
  border-style: solid;
  border-color: transparent transparent transparent #333;
}

/* Dropdown styling */
.profile-dropdown {
  position: absolute;
  top: 50px;
  right: 0;
  background: #fff;
  border: 1px solid #ddd;
  border-radius: 8px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  z-index: 1000;
  width: 120px; 
}

.profile-dropdown-item {
  cursor: pointer;
  padding: 8px;
  transition: background .3s ease;
  margin: 5px;
  border-radius: 5px;
}

.profile-dropdown-item:hover {
  background: #f5f5f5;
}

/* Divider styling */
.profile-dropdown-divider {
  height: 2px;
  width: 90%;
  margin: 0 auto;
  background: linear-gradient(to bottom, #e0e0e0 50%, #f5f5f5 50%);
}