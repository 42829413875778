/* Chat Input Field */
.chat-input {
  align-items: center;
  background: #ededed;
  border: 1px solid #f3f3f3;
  border-radius: 30px;
  display: flex;
  flex-direction: row;
  min-height: 50px; /* Keeps the minimum height consistent */
  justify-content: space-between;
  padding: 5px 10px; /* Adjust padding for better alignment */
  max-width: 40rem;
  width: 100%;
  margin-bottom: 20px;
  box-sizing: border-box; /* Include padding in size calculations */
}

.chat-input-field {
  flex: 1; /* Use all available space */
  overflow-wrap: break-word; /* Wrap long words */
  text-align: start;
  max-height: 150px; /* Limit maximum height */
  background: none;
  border: none;
  font-family: Inter, sans-serif;
  color: rgb(0, 0, 0);
  outline: none;
  resize: none; /* Prevent manual resizing */
  padding: 5px 10px; /* Adds padding inside the textarea */
  font-size: 1rem;
  line-height: 1.5; /* Centers text vertically */
  height: auto; /* Automatically adjust height */
  min-height: 39px; /* Matches single-row height */
  width: 100%;
  box-sizing: border-box; /* Include padding in size */
}

.chat-input-field::placeholder {
  color: #484848;
}

.chat-button-wrapper {
  position: relative;
  display: inline-block;
}

/* Chat Send Button */
.chat-send-button {
  align-items: center;
  background: none;
  border: none;
  color: #a0a0a0;
  display: flex;
  justify-content: center;
  padding: 5px; /* Add some padding for consistent spacing */
}

.chat-send-button.has-text {
  color: #000;
  cursor: pointer;
}

.chat-send-button.has-text:hover i {
  color: #000;
  opacity: 0.7;
  transition: color 0.3s ease-in-out, opacity 0.3s ease-in-out;
}

.chat-send-button i {
  font-size: 1.9rem;
}